import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { selector: String }

  connect () {
    this.scroll()
    document.addEventListener('turbo:render', () => {
      this.scroll()
    })
  }

  scroll () {
    const first = document.querySelector(this.selectorValue)

    if (first) {
      setTimeout(() => first.scrollIntoView({ behavior: 'smooth' }), 100)
    }
  }
}
