import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button', 'input']

  update () {
    if (this.inputTargets.every(input => input.value !== '')) {
      this.buttonTarget.removeAttribute('disabled')
    } else {
      this.buttonTarget.setAttribute('disabled', true)
    }
  }
}
