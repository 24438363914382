import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  WAITING_STEP = 1200
  FINAL_WAITING_STEP = 2800

  static targets = ['loadingItem', 'toggleBusyItem']

  static values = {
    customDelay: Number
  }

  connect () {
    this.interval = window.setInterval(() => {
      const firstLoadingItem = this.loadingItemTargets.find((loadingItem) => loadingItem.ariaBusy === 'true')

      if (firstLoadingItem) {
        firstLoadingItem.removeAttribute('aria-busy')
        firstLoadingItem.scrollIntoView({ behavior: 'smooth', block: 'center' })
      } else {
        window.clearInterval(this.interval)
        window.setTimeout(() => {
          this.loadingItemTargets[0]?.scrollIntoView()
          this.toggleBusyItemTargets.forEach((toggleBusyItem) => {
            toggleBusyItem.ariaBusy = 'false'
          })
        }, this.customDelayValue || this.FINAL_WAITING_STEP)
      }
    }, this.customDelayValue || this.WAITING_STEP)
  }
}
