import Sortable from "stimulus-sortable";

// DOC SortableJS: https://github.com/SortableJS/Sortable
export default class extends Sortable {
  static targets = ["input"];
  static values = {
    conditionShowBlock: String ,
    showBlockQuery: String ,
    inputHiddenName: String,
  };

  connect() {
    super.connect();
    this.updateHiddenInput();
    document.addEventListener("turbo:render", () => {
      this.updateHiddenInput()
    })
  }

  // You can override the `onUpdate` method here.
  onUpdate(event) {
    super.onUpdate(event);
    Array.from(this.allItems()).forEach((item, index) => {
      item.querySelector(".js-rank").innerHTML = `${index + 1}`;
    });

    //
    // DISPLAY / HIDE:
    // This is from the landing page. But can work anywhere.
    //
    if (this.hasConditionShowBlockValue) {
      const blockShowElement = document.querySelector(this.showBlockQueryValue);
      if (this.allItems()[0].id === this.conditionShowBlockValue) {
        blockShowElement.classList.remove("hidden");
      } else {
        blockShowElement.classList.add("hidden");
      }
    }

    this.updateHiddenInput();
  }

  allItems() {
    return this.element.querySelectorAll("li");
  }

  updateHiddenInput() {
    if (this.hasInputTarget) {
      this.inputTarget.value = Array.from(this.allItems()).map(item => item.id).join(",");
      this.inputTarget.dispatchEvent(new Event("change"));
    }
  }

  // You can set default options in this getter for all sortable elements.
  get defaultOptions() {
    return {
      animation: 300,
      delay: 150,
      delayOnTouchOnly: true,
    };
  }
}
