import { Controller } from "@hotwired/stimulus";
import { Loader } from "@googlemaps/js-api-loader";
let debounce = require("lodash/debounce");

// Connects to data-controller="street-view"
export default class extends Controller {
  static targets = ["map"];
  static values = {
    apiKey: String,
    query: String, // query and key values identify uniquely the property
    key: String,
    lat: Number,
    lng: Number,
    addressLat: Number,
    addressLng: Number,
    pano: String,
    heading: Number,
    pitch: Number,
    zoom: Number,
    visible: Boolean,
  };

  initialize() {
    this.setQueryStringParam = debounce(this.setQueryStringParam, 400).bind(
      this
    );
  }

  connect() {
    this.loader = new Loader({
      apiKey: this.apiKeyValue,
      version: "weekly",
    });

    this.loader.load().then(async () => {
      //this._map = this.loadMap();
      this._panorama = await this.loadStreetView();
    });
  }

  // async loadMap() {
  //   const { Map } = await google.maps.importLibrary("maps");
  //   return new Map(this.mapTarget, {
  //     center: { lat: -34.397, lng: 150.644 },
  //     zoom: 8,
  //   });
  // }

  async loadStreetView() {
    const panoramaOptions = {
      position: { lat: this.latValue, lng: this.lngValue },
      imageDateControl: false,
      addressControl: false,
      linksControl: false,
      fullscreenControl: false,
      panControl: false,
      zoomControl: false,
      visible: true,
    };
    panoramaOptions.position = { lat: this.latValue, lng: this.lngValue };
    if (this.hasZoomValue) {
      panoramaOptions.zoom = this.zoomValue;
    }
    if (this.hasHeadingValue && this.hasPitchValue) {
      panoramaOptions.pov = {
        heading: this.headingValue,
        pitch: this.pitchValue,
      };
    }
    const panorama = new google.maps.StreetViewPanorama(
      this.mapTarget,
      panoramaOptions
    );
    panorama.addListener("pano_changed", () => {
      const h = this.hashFromPanorama(panorama);
      this.panoValue = h["pano"];
      this.setQueryStringParam(h);
    });

    panorama.addListener("position_changed", () => {
      const h = this.hashFromPanorama(panorama);
      this.latValue = h["lat"];
      this.lngValue = h["lng"];
      this.setQueryStringParam(h);
    });

    panorama.addListener("pov_changed", () => {
      const h = this.hashFromPanorama(panorama);
      this.headingValue = h["heading"];
      this.pitchValue = h["pitch"];
      this.setQueryStringParam(h);
    });

    panorama.addListener("zoom_changed", () => {
      const h = this.hashFromPanorama(panorama);
      this.zoomValue = h["zoom"];
      this.setQueryStringParam(h);
    });

    return panorama;
  }

  hashFromPanorama(panorama) {
    const position = panorama.getPosition();
    const pov = panorama.getPov();
    return {
      lat: position.lat(),
      lng: position.lng(),
      pano: panorama.getPano(),
      heading: pov.heading,
      pitch: pov.pitch,
      zoom: panorama.getZoom(),
    };
  }

  setQueryStringParam(h = {}) {
    var newHeading = null;

    if (this.addressLatValue) {
      const housePoint = new google.maps.LatLng(
        this.addressLatValue,
        this.addressLngValue
      );
      const streetViewPoint = new google.maps.LatLng(
        this.latValue,
        this.lngValue
      );

      newHeading = google.maps.geometry.spherical.computeHeading(
        streetViewPoint,
        housePoint
      );
    }

    if (this.visibleValue === true) {
      const url = new URL(window.location.href);
      Object.keys(h).forEach((key) => {
        url.searchParams.set(key, h[key]);
      });
      window.history.replaceState({}, "", url);
    } else {
      if (newHeading) {
        this.headingValue = newHeading;
        // This case is for the first view, not super clean but we add the "heading" to the URL of the button "adjust view"
        document.querySelectorAll(".adjust_street_view_btn").forEach((btn) => {
          const url = new URL(btn.href);
          url.searchParams.set("heading", newHeading);
          btn.href = url;
        });

        this.setInputsBeforeSubmit();
      }
    }
  }

  setInputsBeforeSubmit(event) {
    this.deleteAllInputs(event);
    const h = this.hashFromPanorama(this._panorama);
    if (this.hasHeadingValue) {
      h.heading = this.headingValue;
    }
    Object.keys(h).forEach((key) => {
      this.element.appendChild(this.buildHiddenInput(key, h[key]));
    });
    this.element.appendChild(this.buildHiddenInput("key", this.keyValue));
    this.element.appendChild(this.buildHiddenInput("query", this.queryValue));
  }

  deleteAllInputs(event) {
    this.element
      .querySelectorAll("input[type=hidden][data-dynamic]")
      .forEach((input) => {
        input.remove();
      });
  }

  buildHiddenInput(name, value) {
    const hiddenField = document.createElement("input");
    hiddenField.setAttribute("type", "hidden");
    hiddenField.setAttribute("value", value);
    hiddenField.setAttribute("id", name);
    hiddenField.setAttribute("data-dynamic", "");
    hiddenField.name = `street_view[${name}]`;
    return hiddenField;
  }
}
