import { Controller } from '@hotwired/stimulus'
import { useResize } from 'stimulus-use'

export default class extends Controller {
  static targets = ['label']

  connect () {
    useResize(this)
  }

  resize () {
    if (this.element.offsetWidth < this.element.scrollWidth) {
      this.element.removeAttribute('aria-expanded')
    } else {
      this.element.setAttribute('aria-expanded', 'true')
    }
  }
}
