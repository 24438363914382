import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["step", "anchor"]

  connect() {
    const observer = new IntersectionObserver(this.intersectionCallback.bind(this), {rootMargin: "0px 0px -50% 0px"})
    this.anchorTargets.forEach(anchor => observer.observe(anchor))
  }

  intersectionCallback(entries) {
    entries.forEach(entry => {
      this.stepTargets.forEach(step => {
        if (step.dataset.scrollStepsId == entry.target.dataset.scrollStepsId) {
          if (entry.isIntersecting || entry.boundingClientRect.y < 0) {
            step.setAttribute("aria-selected", "true")
          } else {
            step.setAttribute("aria-selected", "false")
          }
        }
      })
    })
  }

}
