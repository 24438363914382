import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['element']
  static values = {
    attribute: String
  }

  toggle () {
    this.elementTargets.forEach((element) => {
      if (element.hasAttribute(this.attributeValue)) {
        element.removeAttribute(this.attributeValue)
      } else {
        element.setAttribute(this.attributeValue, true)
      }
    })
  }

  set () {
    this.elementTargets.forEach((element) => {
      element.setAttribute(this.attributeValue, true)
    })
  }

  unset () {
    this.elementTargets.forEach((element) => {
      element.removeAttribute(this.attributeValue)
    })
  }
}
