import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  DESTRUCT_AFTER_MS = 1000

  static values = {
    destructAfter: Number
  }

  connect () {
    const destructAfter = this.destructAfterValue || this.DESTRUCT_AFTER_MS
    setTimeout(() => this.element.remove(), destructAfter)
  }
}
