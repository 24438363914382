import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="svg-text-changer"
export default class extends Controller {
  static values = {
    aSelector: { type: String, default: "text#a > tspan"},
    bSelector: { type: String, default: "text#b > tspan"},
    cSelector: { type: String, default: "text#c > tspan"},
    dSelector: { type: String, default: "text#d > tspan"},
    eSelector: { type: String, default: "text#e > tspan"},
    fSelector: { type: String, default: "text#f > tspan"},
    gSelector: { type: String, default: "text#g > tspan"},
    aQuantity: { type: Number, default: 0},
    bQuantity: { type: Number, default: 0},
    cQuantity: { type: Number, default: 0},
    dQuantity: { type: Number, default: 0},
    eQuantity: { type: Number, default: 0},
    fQuantity: { type: Number, default: 0},
    gQuantity: { type: Number, default: 0},
  }
  connect() {
    this.updateQuantity()
  }

  updateQuantity() {
    if (this.element.querySelector(this.aSelectorValue) !== null && this.aQuantityValue > 0) {
      this.element.querySelector(this.aSelectorValue).textContent = this.aQuantityValue;
    }
    if (this.element.querySelector(this.bSelectorValue) !== null && this.bQuantityValue > 0) {
      this.element.querySelector(this.bSelectorValue).textContent = this.bQuantityValue;
    }
    if (this.element.querySelector(this.cSelectorValue) !== null && this.cQuantityValue > 0) {
      this.element.querySelector(this.cSelectorValue).textContent = this.cQuantityValue;
    }
    if (this.element.querySelector(this.dSelectorValue) !== null && this.dQuantityValue > 0) {
      this.element.querySelector(this.dSelectorValue).textContent = this.dQuantityValue;
    }
    if (this.element.querySelector(this.eSelectorValue) !== null && this.eQuantityValue > 0) {
      this.element.querySelector(this.eSelectorValue).textContent = this.eQuantityValue;
    }
    if (this.element.querySelector(this.fSelectorValue) !== null && this.fQuantityValue > 0) {
      this.element.querySelector(this.fSelectorValue).textContent = this.fQuantityValue;
    }
    if (this.element.querySelector(this.gSelectorValue) !== null && this.gQuantityValue > 0) {
      this.element.querySelector(this.gSelectorValue).textContent = this.gQuantityValue;
    }
  }
}
