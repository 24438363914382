import { Controller } from '@hotwired/stimulus'
import { application } from './application'

let asyncControllerLoaded = false

export default class extends Controller {
  connect () {
    if (!asyncControllerLoaded) {
      asyncControllerLoaded = true
      import('./map_render_building_controller_async').then((controller) => {
        application.load([{
          identifier: 'map-render-building',
          controllerConstructor: controller.default
        }])
      }, () => { })
    }
  }
}
