import { Controller } from '@hotwired/stimulus'
import { application } from './application'

let asyncControllerLoaded = false

export default class extends Controller {
  connect () {
    if (!asyncControllerLoaded) {
      asyncControllerLoaded = true
      import('./map_address_confirm_controller_async').then((controller) => {
        application.load([{ identifier: 'map-address-confirm', controllerConstructor: controller.default }])
      }, () => { })
    }
  }

  // Implement empty methods to avoid errors if the async controller is not loaded
  // yet when the methods are called
  freeze () {
  }

  unfreeze () {
  }
}
