import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['frame', 'queryParamsInput', 'hint']

  static values = {
    url: String
  }

  connect () {
  }

  update () {
    const urlObj = new URL(this.urlValue)
    const paramsValue = this.queryParamsInputTarget.value
    const frame = this.frameTarget

    if (paramsValue) {
      const inputParams = JSON.parse(this.queryParamsInputTarget.value)
      const queryParams = {
        latitude: parseFloat(inputParams.latitude).toFixed(6),
        longitude: parseFloat(inputParams.longitude).toFixed(6)
      }

      for (const [key, value] of Object.entries(queryParams)) {
        urlObj.searchParams.append(key, value);
      }
      frame.src = urlObj.toString()
      frame.removeAttribute('hidden')
      this.hintTarget.setAttribute('hidden', true)
    } else {
      frame.setAttribute('hidden', true)
      this.hintTarget.removeAttribute('hidden')
    }
  }
}
