import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="selector"
export default class extends Controller {
  static targets = ["description", "input", "step"];
  static values = {
    selected: { type: String, default: null },
  };
  static classes = ["selected"];

  connect() {
    this.init()
    document.addEventListener("turbo:render", () => {
      this.init()
    })
  }

  init() {
    if (this.selectedValue) {
      this.selectByValue(this.selectedValue);
      this.updateHiddenInput();
    }
    if (this.inputTarget.value) {
      this.selectByValue(this.inputTarget.value);
    }
  }

  minus(event) {
    const index = this.stepTargets.indexOf(this.selectedButton());
    this.selectByIndex(index > 0 ? index - 1 : this.stepTargets.length - 1);
  }

  plus(event) {
    const index = this.stepTargets.indexOf(this.selectedButton());
    this.selectByIndex(index < this.stepTargets.length - 1 ? index + 1 : 0);
  }

  select(event) {
    this.selectByValue(event.target.dataset.selectorOption);
  }

  selectByIndex(index) {
    this.descriptionTarget.innerHTML = "&nbsp;";
    if (this.selectedButton()) {
      this.selectedButton().classList.remove(
        ...this.selectedClasses
      );
    }
    const button = this.buttonFromIndex(index);

    this.selectedValue = button.dataset.selectorOption;

    button.classList.add(
      ...this.selectedClasses
    );

    this.descriptionTarget.innerHTML = button.dataset.selectorDescription;

    this.updateHiddenInput();
  }

  selectByValue(value) {
    const button = this.buttonFromChoice(value);

    this.selectByIndex(this.stepTargets.indexOf(button));
  }

  updateHiddenInput() {
    if (!this.inputTarget) return;
    this.inputTarget.value = this.selectedValue;
    this.inputTarget.dispatchEvent(new Event("change"));
  }

  buttonFromChoice(choice) {
    return this.stepTargets.find(
      (step) => step.dataset.selectorOption == choice
    );
  }

  buttonFromIndex(index) {
    return this.stepTargets[index];
  }

  selectedButton() {
    return this.buttonFromChoice(this.selectedValue);
  }

}
