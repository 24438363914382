import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];

  connect() {
    super.connect();
    this.disableOrEnableButton();

    var controller = this;

    const inputs = this.element.querySelectorAll("input, select");

    inputs.forEach((input) => {
      input.addEventListener("change", () => {
        controller.disableOrEnableButton();
      });
    });
  }

  // You can override the `onUpdate` method here.
  submit(event) {
    event.preventDefault();

    let invalidField = this.invalidField();

    if (invalidField) {
      this.scrollTo(this.elementByDataValidationBlock(invalidField));
      return false;
    }

    return this.element.submit();
  }

  disableOrEnableButton() {
    if (this.invalidField()) {
      this.buttonTarget.classList.add("opacity-40");
    } else {
      this.buttonTarget.classList.remove("opacity-40");
    }
  }

  invalidField() {
    if (this.inputIsEmpty("winter_comfort")) {
      return "winter_comfort";
    }
    if (this.inputIsEmpty("summer_comfort")) {
      return "summer_comfort";
    }
    if (this.inputIsEmpty("humidity")) {
      return "humidity";
    }
    if (this.inputIsEmpty("acoustic")) {
      return "acoustic";
    }

    if (this.inputByName("priorities").value.split(",")[0] === "dpe_letter") {
      if (this.radioInputNotSelected("dpe_target")) {
        return "dpe_target";
      }
    }

    if (this.inputByName("constraints").value.split(",")[0] === "budget") {
      if (this.inputIsEmpty("max_budget")) {
        return "max_budget";
      }
    }

    return false;
  }

  elementByDataValidationBlock(name) {
    return this.element.querySelector(`[data-validation-block="${name}"]`);
  }

  inputByName(name) {
    return this.element.querySelector(
      `input[name="${name}"],select[name="${name}"]`
    );
  }

  radioInputNotSelected(name) {
    var elementChecked = this.element.querySelector(
      `input[name="${name}"]:checked`
    );
    return !elementChecked;
  }

  inputIsEmpty(name) {
    var input = this.inputByName(name);

    if (!input) {
      return true;
    }

    return input.value === "";
  }

  scrollTo(element) {
    element.scrollIntoView({ behavior: "smooth", block: "center" });
  }
}
