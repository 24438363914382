import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  submit() {
    let file = this.inputTarget.files[0];
    if (file && file.type === "text/xml") {
      this.element.requestSubmit();
    } else {
      alert("Please select a valid XML file.");
    }
  }
}
