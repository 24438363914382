import { Application } from '@hotwired/stimulus'
import { Slideover, Dropdown } from 'tailwindcss-stimulus-components'
import { Autocomplete } from 'stimulus-autocomplete'
import ContentLoader from 'stimulus-content-loader'
import Sortable from 'stimulus-sortable'
import { StreamActions, visit } from '@hotwired/turbo'

StreamActions.redirect = function () {
  visit(this.target)
}

StreamActions.frame_visit = function () {
  const frame = this.getAttribute('frame')

  visit(this.getAttribute('url'), { frame })
}

StreamActions.set_attribute = function () {
  const selector = this.getAttribute('selector')
  const attribute = this.getAttribute('attribute')
  const value = this.getAttribute('value')

  document.querySelectorAll(selector).forEach(element => {
    element.setAttribute(attribute, value)
  })
}

const application = Application.start()
application.register('dropdown', Dropdown)
application.register('slideover', Slideover)
application.register('content-loader', ContentLoader)
application.register('sortable', Sortable)
application.register('autocomplete', Autocomplete)

// Configure Stimulus development experience
application.debug = process.env.NODE_ENV === 'development'
window.Stimulus = application

export { application }
