import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    url: String
  }

  connect () {
    window.Turbo.navigator.history.replace(new URL(this.urlValue, window.location.origin))
  }
}
