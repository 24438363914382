import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.formatInput()
  }

  update() {
    this.formatInput()
  }

  formatInput() {
    let value = this.inputTarget.value

    // Remove characters that are not digits, spaces, or '+'
    value = value.replace(/[^\d+]/g, '')

    // Ensure only one '+' at the beginning (if present)
    value = value.replace(/\+/g, (match, offset) => offset === 0 ? match : '')

    this.inputTarget.value = value.trim()
  }
}
