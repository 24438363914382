// Entry point for the build script in your package.json
import '@hotwired/turbo-rails'
import './controllers'

import { Application } from '@hotwired/stimulus'
import { Popover } from 'tailwindcss-stimulus-components'

const application = Application.start()
application.register('popover', Popover)

// import flatpickr from "flatpickr";

// Copy to clipboard
window.copyContentFunc = async (blockId) => {
  const text = document.getElementById(blockId).innerHTML
  try {
    await navigator.clipboard.writeText(text)
  } catch (err) {
    // failed.
  }
}
