import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="work-plan-recommendation-form"
export default class extends Controller {
  static targets = [
    "priority",
    "priorityContainer",
    "dpeTargetContainer",
    "constraint",
    "constraintContainer",
    "maxBudgetContainer"
  ]

  connect() {
    this.updateDpeTargetClasses();
    this.updateConstraintClasses();
  }

  updateDpeTargetClasses() {
    this.toggleSelectVisibility(this.priorityTarget, this.priorityContainerTarget, this.dpeTargetContainerTarget, 'dpe_letter')
  }

  updateConstraintClasses() {
    this.toggleSelectVisibility(this.constraintTarget, this.constraintContainerTarget, this.maxBudgetContainerTarget, 'budget')
  }

  toggleSelectVisibility(mainSelect, mainSelectContainer, secondarySelectContainer, showSecondSelectValue) {
    if (mainSelect.value === showSecondSelectValue) {
      secondarySelectContainer.classList.remove("hidden");
      mainSelectContainer.classList.add("xl:w-3/5")
      mainSelect.classList.add("xl:rounded-r-none")
    } else {
      secondarySelectContainer.classList.add("hidden");
      mainSelectContainer.classList.remove("xl:w-3/5")
      mainSelect.classList.remove("xl:rounded-r-none")
    }
  }
}
