import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    eventName: String
  }

  connect () {
    this.element.dispatchEvent(new CustomEvent('connect'))
  }

  dispatch () {
    window.dispatchEvent(new CustomEvent(this.eventNameValue))
  }
}
