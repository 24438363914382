import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['element']

  toggle (e) {
    if (this.shouldSkip(e)) return

    this.elementTargets.forEach((element) => {
      element.hidden = !element.hidden
    })
  }

  show (e) {
    if (this.shouldSkip(e)) return

    this.elementTargets.forEach((element) => {
      element.hidden = false
    })
  }

  hide (e) {
    if (this.shouldSkip(e)) return

    this.elementTargets.forEach((element) => {
      element.hidden = true
    })
  }

  shouldSkip (e) {
    return e.target.dataset.displayToggleSkip
  }
}
