import { Controller } from '@hotwired/stimulus'
import { useIntersection } from 'stimulus-use'

export default class extends Controller {
  static targets = ['dialog']

  connect () {
    this.element.dispatchEvent(new CustomEvent('connect'))
    useIntersection(this)
  }

  open () {
    this.closeAllDialogs()
    this.dialogTarget.showModal()
  }

  close () {
    this.dialogTarget.close()
  }

  closeAllDialogs () {
    document.querySelectorAll('dialog[open]').forEach(dialog => dialog.close())
  }
}
