import { Dropdown } from "tailwindcss-stimulus-components";

export default class extends Dropdown {
  connect() {
    super.connect();
    // This trick is from this article https://dev.to/leastbad/the-best-one-line-stimulus-power-move-2o90
    // It allows you to access the stimulus controller instance from the element like this:
    // document.querySelector('[data-controller="dropdown"]').dropdown
    // document.querySelector('#menu').dropdown.toggle()
    // For this case: document.querySelector(".js-profile-menu").dropdown.toggle()
    this.element[this.identifier] = this;
  }

  toggle(event) {
    super.toggle();
  }

  enter(event) {}

  leave(event) {}
}
