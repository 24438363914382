import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["input", "showForValue", "hideForValue"]

  update() {
    this.showForValueTargets.forEach(element => {
      element.hidden = !!this.parseTargetValue(element) == this.value()
    })

    this.hideForValueTargets.forEach(element => {
      element.hidden = this.parseTargetValue(element) == this.value()
    })

  }

  parseTargetValue(element) {
    const value = element.dataset.targetValue

    if (value == "true") {
      return true
    }
    if (value == "false") {
      return false
    }
    return value
  }

  value() {
    if (this.inputTargets.length == 1) {
      if (this.inputTarget.type == "checkbox") {
        return this.inputTarget.checked
      }
      return this.inputTarget.value
    }

    return this.inputTargets.find(input => input.checked).value
  }
}
