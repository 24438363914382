import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "content"]
  static values = {
    active: Number,
  };

  connect() {
    this.index = this.activeValue || 0
    this.showTab()
  }

  select(event) {
    this.index = parseInt(event.currentTarget.dataset.index)
    this.showTab()
  }

  showTab() {
    // hide all tabs
    this.contentTargets.forEach((content) => content.setAttribute("hidden", ""))

    this.contentTargets.forEach((content, index) => {
      // display only the one we want
      if (parseInt(content.dataset.index) === this.index) {
        content.removeAttribute("hidden");
      }
    })

    // disable all tabs
    this.tabTargets.forEach((tab) => tab.ariaSelected = false)

    this.tabTargets.forEach((tab, index) => {
      // Set clicked tab to active
      if (index === this.index) {
        tab.ariaSelected = true
      }
    })
  }
}
